// Copyright (C) 2024 Aware, Inc - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

const autocapture_frequency_milliseconds = 1000;
const autocapture_startup_delay_milliseconds = 4000;
const videoRecordingFaceCheckInterval = 5;
const maxVoiceRecordingLength = 30;
const voiceLengthCheckInterval = 1;

const knomi_analyze_host = "https://mobileauth.aware-demos.com/preface";
const knomi_liveness_host = "https://mobileauth.aware-demos.com/faceliveness";
const face_endpoint_roi = '/calculateROI';
const face_endpoint_autocapture = '/autocaptureVideoEncrypted';
const face_endpoint_analyze = '/analyzeEncrypted';

// KnomiWeb WAMS library objects
let cameraObj = null;
let payloadObj = null;

// Backend server URLS
let faceLivenessUrl = knomi_liveness_host + face_endpoint_analyze;

// These are to either the Face or document URLs
// depending on what the target is set to.
let autocaptureUrl = knomi_analyze_host + face_endpoint_autocapture;
let roiUrl = knomi_analyze_host + face_endpoint_roi;

// Indicates an error has occurred
let hasError = false;

// Stores the region of interest in {x:0,y:0,width:0,height:0} format
let regionOfInterest = null;

let isCameraInitialized = false;
let isAutocapturing = false;

// Holds the id of the capture loop timeout, so it can be cancelled
let autocaptureLoopTimeoutId = null;

// Used to adjust the video brightness
let brightnessDegree = 1.2;
let insufficientLightCounter = 0;

// Holds the capture target string. e.g FACE, PASSPORT etc.
let captureTarget = "FACE";

// When video capture starts
let startTime;

const FEEDBACK_ONE = "centralize o rosto na moldura";
const FEEDBACK_TWO = "aproxime o rosto";
const FEEDBACK_THREE = 'tudo certo!';

function showError(message) {
	throw message;
}

function showFeedback(feedback) {
	let feedbackSection = document.getElementById("feedbackSection");
	let feedbackText = document.getElementById("feedbackText");
	if (feedback && feedback.length > 0) {
		feedbackSection.style.display = 'block';
		feedbackText.value = feedback;
	} else {
		feedbackText.value = "";
	}
}

function showResults(message) {
	console.info('Result: ', message)
}

function getResultAutocaptureFeedbackList(result) {
	let feedback = [];
	if (Object.prototype.hasOwnProperty.call(result, "frameResults")) {

		for (let frameResult of result.frameResults) {
			if (frameResult.feedback.length === 0) continue;
			feedback = feedback.concat(frameResult.feedback);
		}
		return feedback;
	}
	return null;
}

function getResultAutocaptureCapturedStatus(autocaptureResponse) {
	if (hasProp(autocaptureResponse, "results.captured"))
		return autocaptureResponse.results.captured;
	return false;
}

function isResultLive(result) {
	if (!hasProp(result, "video.liveness_result.score")) {
		return false;
	}
	return (result.video.liveness_result.score === 100);
}

function isResultSpoof(result) {
	if (!hasProp(result, "video.liveness_result.score")) {
		return false;
	}
	return (result.video.liveness_result.score === 0);
}

async function startAutocapture() {
	if (hasError) {
		return;
	}
	updateUserInterface();
	startTime = new Date().getTime();
	await setUpROI();
}

async function setUpROI() {
	const video = document.getElementById("previewWindow");
	if (!video.videoWidth) {
		// Video isn't running, wait and try again
		setTimeout(setUpROI, 500);
		return;
	}

	await requestROI();
	isAutocapturing = true;

	if (autocaptureLoopTimeoutId) {
		clearTimeout(autocaptureLoopTimeoutId);
	}
	updateUserInterface();
	cameraObj.Play();
	showBadIndicator();
}

async function handleButtonClick() {
	let captureButton = document.getElementById("captureBtn");
	captureButton.style.display = 'none';
	await setUpROI();
	// Start autocaptureLoop via setTimeout to allow the browser to setup the camera.
	autocaptureLoopTimeoutId = setTimeout(autocaptureLoop, autocapture_frequency_milliseconds);
}

function stopTask() {
	if (hasError) {
		return;
	}
	isAutocapturing = false;
	updateUserInterface();
	cameraObj.Stop();
	isCameraInitialized = false;
	clearTimeout(autocaptureLoopTimeoutId);
	setProgress(0);
}

async function autocaptureLoop() {
	if (hasError) return;
	let loopStartTime = new Date().getTime();
	if (!isAutocapturing) {
		setProgress(0);
		return;
	}
	try {
		// Get frames and send to autocapture analysis server
		await collectCameraFrames();
		const autocapturePayload = payloadObj.GetAutocapturePayload(cameraObj);
		setProgress(50);
		if (autocapturePayload !== "") {
			const { data: autoCaptureAnalysisResult } = await window.faceanalyzerAPI(autocapturePayload);
			showFeedback(getCorrectReturn(autoCaptureAnalysisResult.frameResults[0].feedback[0]));

			if (("error") in autoCaptureAnalysisResult) {
				cameraObj.Pause();
				showResults(autoCaptureAnalysisResult.error.description);
				insufficientLightCounter = 0;
				isAutocapturing = false;
				updateUserInterface();
			}

			const feedbackList = getResultAutocaptureFeedbackList(autoCaptureAnalysisResult);
			if (feedbackList != null) {
				if (feedbackList.indexOf("INSUFFICIENT_LIGHTING") !== -1) {
					insufficientLightCounter++;
				} else {
					insufficientLightCounter = 0;
				}
			}

			let hasCapturableImage = getResultAutocaptureCapturedStatus(autoCaptureAnalysisResult);
			if (hasCapturableImage) {
				showGoodIndicator();
				if ((loopStartTime - startTime) > autocapture_startup_delay_milliseconds) {
					isAutocapturing = false;
					await checkForLiveness();
				}
			} else {
				showBadIndicator();
			}
		}
		if (isAutocapturing) {
			const elapsedTime = (new Date().getTime() - loopStartTime);
			delay = Math.max(0, autocapture_frequency_milliseconds - elapsedTime);
			autocaptureLoopTimeoutId = setTimeout(autocaptureLoop, delay);
		} else {
			setProgress(100);
			showFeedback(FEEDBACK_THREE)
			updateUserInterface();
		}
	} catch (error) {
		console.error("ERRROR:", error);
		cameraObj.Pause();
		showResults(error);
		insufficientLightCounter = 0;
		isAutocapturing = false;
		updateUserInterface();
	}
}

function getCorrectReturn(value) {
	const values = {
		"COMPLIANT_IMAGE": "Não se mexa",
		"IMAGE_RESOLUTION_TOO_LOW": "Imagem de baixa qualidade",
		"NO_FACE_DETECTED": "Rosto não encontrado; Posicione o rosto na moldura",
		"INVALID_POSE": "Posicione seu rosto na moldura",
		"FACE_TOO_FAR": "Aproxime o celular",
		"FACE_TOO_CLOSE": "Afaste o celular",
		"FACE_ON_LEFT": "Centralize seu rosto na moldura; Se posicione mais à direita",
		"FACE_ON_RIGHT": "Centralize seu rosto na moldura; Se posicione mais à esquerda",
		"FACE_TOO_HIGH": "Centralize seu rosto na moldura; Se posicione mais abaixo",
		"FACE_TOO_LOW": "Centralize seu rosto na moldura; Se posicione mais para cima",
		"INSUFFICIENT_LIGHTING": "Ambiente muito escuro;  Procure um ambiente mais iluminado",
		"LIGHT_TOO_BRIGHT": "Ambiente muito claro;/ Procure um ambiente menos iluminado",
		"TOO_MUCH_BLUR": "Fora de foco; reposicione seu rosto na moldura",
		"GLASSES_PRESENT": "Retire os óculos",
		"SMILE_PRESENT": "Não sorria",
		"FOREHEAD_COVERING": "Retire objetos da cabeça",
		"BACKGROUND_TOO_CLUTTERED": "Procure um outro local",
		"BACKGROUND_TOO_BRIGHT": "Ambiente muito claro;  Procure um ambiente menos iluminado",
		"BACKGROUND_TOO_DARK": "Ambiente muito escuro; Procure um ambiente mais iluminado",
		"LEFT_EYE_CLOSED": "Abra os olhos",
		"RIGHT_EYE_CLOSED": "Abra os olhos",
		"LEFT_EYE_OBSTRUCTED": "Abra os olhos",
		"RIGHT_EYE_OBSTRUCTED": "Abra os olhos",
		"OFF_ANGLE_GAZE": "Posicione seu rosto na moldura; olhe para frente",
		"HEAVY_FRAMES": "Retire os óculos",
		"GLARE": "Retire os óculos",
		"DARK_GLASSES": "Retire os óculos",
		"FACIAL_SHADOWING": "Retire os óculos",
		"RED_EYE": "Procure uma iluminação melhor",
		"UNNATURAL_LIGHTING_COLOR": "Procure uma iluminação melhor",
		"FACE_OBSTRUCTED": "Retire objetos em frente à sua face",

	}
	return values[value] || FEEDBACK_TWO;
}

function enableCaptureButton(enabled) {
	let captureButton = document.getElementById("captureBtn");
	if (enabled) {
		captureButton.disabled = false;
		captureButton.opacity = "1";
	} else {
		captureButton.disabled = true;
		captureButton.opacity = "0";
	}
}

function hideCamera() {
	let previewSection = document.getElementById("previewSection");
	previewSection.style.visibility = "hidden";
}

async function postPayloadFetch(url, body) {
	let response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: body,
	});

	if (response.ok) {
		const data = await response.json();
		if (data) {
			return data;
		}
	} else {
		const error_text = await response.text();
		if (error_text !== "") {
			throw new Error(error_text);
		}
		throw new Error("Received invalid response from the server");
	}
}

function collectCameraFrames() {
	if (insufficientLightCounter !== 0 && insufficientLightCounter % 3 === 0) {
		brightnessDegree = brightnessDegree + 0.4;
		cameraObj.SetPropertyDouble(Module.CameraProperty.BRIGHTNESS, brightnessDegree);
	}

	if (insufficientLightCounter > 10) {
		throw new Error('Brightness has been increased too many times...');
	}

	cameraObj.CollectFrames();
}

async function checkForLiveness() {
	cameraObj.Pause();
	const workflow = cameraObj.IsMobileDevice() ? Module.FOXTROT4 : Module.HOTEL4;
	payloadObj.SetPropertyString(Module.PayloadProperty.WORKFLOW, workflow);
	const payload = payloadObj.GetAnalyzePayload(cameraObj);

	// Chamada antiga para o backend, será removido na Bio 2.0
	window.preRegister(payload);

	// Fluxo Bio 2.0
	// window.prelivenessAPI(payload);
}

function setReadyReturn(response) {
	const frontImage = document.querySelector('#frontImage');
	frontImage.setAttribute('aria-response', JSON.stringify(response));
}

function updateUserInterface() {
	let previewSection = document.getElementById("previewSection");
	if (isCameraInitialized && isAutocapturing) {
		enableCaptureButton(true);
		previewSection.style.display = 'block';
	}
}

async function requestROI() {
	try {
		let roiPayload = payloadObj.GetRegionOfInterestPayload(cameraObj);
		showBadIndicator();
		updateUserInterface();
	} catch (error) {
		showError("Received error trying to get region of interest. See log for details.");
		hideCamera();
		enableCaptureButton(false);
		hasError = true;
		console.error(error);
	}
}

function showGoodIndicator() {
	drawFaceRegionOfInterest();

}

function showBadIndicator() {
	drawFaceRegionOfInterest();
}

/*
 * Camera Callbacks
 */

function cameraFinishedInitializingSuccess() {
	isCameraInitialized = true;
	startAutocapture();
}

function cameraFinishedInitializingFailure(error) {
	isCameraInitialized = false;
	showError(error);
	hideCamera();
	enableCaptureButton(false);
	hasError = true;
	updateUserInterface();
}

function setProgress(percentage) {
	percentage = Math.floor(percentage);
	const progressBar = document.querySelector('#speech-progress');
	progressBar.style.width = percentage + '%';
	progressBar.setAttribute('aria-valuenow', percentage);
	if (percentage === 50) {
		progressBar.classList.add('half-filled');
	} else {
		progressBar.classList.remove('half-filled');
	}
}

/* Callback function that is called to post a recording
	 frame to the face analyzer for analysis */
async function recordingAnalyzeFrame(autocapturePayload) {
	const response = await fetch(autocaptureUrl, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: autocapturePayload,
	})

	const results = await response.json();
	return results;
}

/*
 * Utility Functions
 */

// Returns true if a property path exists
function hasProp(obj, prop) {
	let props = prop.split(".");
	const length = props.length;
	if (length === 0)
		return false;
	for (let i = 0; i < length; i++) {
		const key = props[i];
		if (!Object.prototype.hasOwnProperty.call(obj, key))
			return false;
		obj = obj[key];
	}
	return true;
}

const lowerLimit = (x, limits) => {
	const less = limits.filter(limit => x < limit);
	return less.length === 0 ? x : Math.max.apply(null, less);
};

const upperLimit = (x, limits) => {
	const greater = limits.filter(limit => x > limit);
	return greater.length === 0 ? x : Math.min.apply(null, greater);
};

const limitIt = (value, lowerLimits, upperLimits) => {
	return upperLimit(lowerLimit(value, lowerLimits), upperLimits);
};

/*
 * Region of Interest Drawing Functions
 */
function drawFaceRegionOfInterest() {
	window.addEventListener('resize', (a) => drawFaceRegionOfInterest(), true);

	const video = document.getElementById("previewWindow");
	const canvas = document.getElementById("ovalOverlay");

	if (canvas) {
		const context = canvas.getContext('2d', { willReadFrequently: true });

		canvas.width = video.videoWidth;
		canvas.height = video.videoHeight;

		const width = canvas.width;
		const height = canvas.height;

		const faceWidthRadio = 0.50;
		const faceHeightRadio = 0.55;

		const faceWidth = width * faceWidthRadio;
		const faceHeight = height * faceHeightRadio;

		const faceX = width / 2;
		const faceY = height / 2 - 50;

		// fundo escuro translucido
		context.fillStyle = 'rgba(0,0,0,0.5)';
		context.fillRect(0, 0, width, height);
		// elipse transparente no centro
		context.globalCompositeOperation = 'destination-out';
		context.beginPath();
		context.ellipse(faceX, faceY, faceWidth / 2, faceHeight / 2, 0, 0, Math.PI * 2);
		context.fill();
		context.globalCompositeOperation = 'source-over';
	}
};

function registerControls() {
	let captureButton = document.getElementById("captureBtn");
	captureButton.addEventListener('click', handleButtonClick);
}

// This is called by the KnomiWeb WASM Library when it
// and the rest of the JavaScript is loaded
function KnomiWebAllReady() {
	initApp();
}

async function initApp() {
	console.info(Module.GetVersionStr());
	console.info(Module.GetLegalCopyright());

	try {
		payloadObj = new Module.Payload();
		cameraObj = await new Module.Camera();

		cameraObj.SetPropertyString(Module.CameraProperty.FINISHED_INITIALIZING_SUCCESS_FN, "cameraFinishedInitializingSuccess");
		cameraObj.SetPropertyString(Module.CameraProperty.FINISHED_INITIALIZING_FAILURE_FN, "cameraFinishedInitializingFailure");
		cameraObj.SetPropertyString(Module.CameraProperty.RECORDING_ANALYZE_FRAME_FN, "recordingAnalyzeFrame");
		cameraObj.SetPropertyInt(Module.CameraProperty.RECORDING_ANALYZE_INTERVAL, videoRecordingFaceCheckInterval);
		cameraObj.SetPropertyInt(Module.CameraProperty.ORIENTATION,
			cameraObj.IsMobileDevice()
				? Module.CameraOrientation.PORTRAIT.value
				: Module.CameraOrientation.LANDSCAPE.value);
		cameraObj.SetPropertyString(Module.CameraProperty.CAMERA_TAG_ID, "previewWindow");
		cameraObj.SetPropertyInt(Module.CameraProperty.VOICE_MAX_TIME, maxVoiceRecordingLength);
		cameraObj.SetPropertyInt(Module.CameraProperty.VOICE_INTERVAL, voiceLengthCheckInterval);
		cameraObj.SetPropertyString(Module.CameraProperty.PROCESSOR_URL, "bin/KnomiWebProcessor.js");

	} catch (e) {
		if (e.message === Module.TRIAL_EXPIRATION_PASSED) {
			alert("Trial expiration has passed. See log for details.");
			console.error("The software tryout period has expired. Please Contact Aware, Inc. at support@aware.com.");
		} else if (e.message === Module.PLATFORM_NOT_SUPPORTED) {
			alert("Platform not supported. See log for details.");
			console.error("Platform not supported. KnomiWeb is configured for emulator detection which is only supported on mobile browsers.");
		} else {
			throw e;
		}
		return
	}

	registerControls();

	cameraObj.Initialize();

	// Update UI
	updateUserInterface();

	showFeedback(FEEDBACK_ONE);
	setProgress(0);
}